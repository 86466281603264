.notificationReq-modal {
  position: fixed;
  z-index: 9999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.notificationReq-modal-content {
  background-color: #fefefe;
  margin: 20% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
}

.notificationReq-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.notificationReq-modal-header h2 {
  margin: 0;
}

.notificationReq-close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
}

.notificationReq-modal-body {
  margin-bottom: 20px;
}

.notificationReq-modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.notificationReq-button {
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.3s;
}
.notificationReq-button:first-of-type {
  background-color: none;
  color: #007bff;
  border: 0.1rem solid #007bff;
}
.notificationReq-button:first-of-type:hover {
  color: white;
  background-color: #007bff;
}
.notificationReq-button:last-of-type {
  background-color: #007bff;
  color: #fff;
  border: none;
}
.notificationReq-button:last-of-type:hover {
  background-color: rgb(3, 80, 163);
}

.notificationReq-modal-content {
  animation: modal-show 0.3s ease-out;
}

@keyframes modal-show {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
