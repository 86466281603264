.mb-3 input {
  transform: scale(1);
}

.mb-3 label {
  font-size: 16px;
}

@media (max-width: 992px) {
  .mb-3 input {
    transform: scale(0.9);
  }

  .mb-3 label {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .mb-3 input {
    transform: scale(0.8);
  }

  .mb-3 label {
    font-size: 12px;
  }
}
