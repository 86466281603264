.article {
  width: 40%;
  margin: 8rem auto;
  position: relative;
}
.loading-article {
  height: 100vh;
  margin: auto;
}

@media screen and (max-width: 767px) {
  .article {
    width: 90%;
    margin: 8rem auto;
    position: relative;
  }
}
