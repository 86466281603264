* {
  padding: 0px;
}

.book__coverpage {
  transition: 0.15s ease;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.book__coverpage:hover {
  transform: scale(1.02);
}

.book__coverpage img {
  width: 100%;
  height: 250px;
  object-fit: fill;
  border-radius: 10px 10px 0px 0px;
}
.book__coverpage:hover p {
  white-space: normal;
  overflow: visible;
  text-overflow: unset;
}
.book__coverpage p {
  flex-grow: 1;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  letter-spacing: 0.2px;
  padding: 5px 10px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.33;
  color: rgb(34, 34, 34);
}

.book__coverpage label {
  font-family: 'Roboto';
  font-size: 15px;
  color: rgb(102, 102, 102);
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.below--title--div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.below--title--div p {
  color: rgb(102, 102, 102);
  display: inline-block;
}

@media (max-width: 1199px) {
  .book__coverpage img {
    height: 220px;
    object-fit: fill;
  }
  .book__coverpage p {
    font-size: 12px;
  }
  .book__coverpage label {
    font-size: 12px;
  }
}

@media (max-width: 991px) {
  .book__coverpage img {
    height: 170px;
    object-fit: fill;
  }
  .book__coverpage p {
    font-size: 10px;
  }
  .book__coverpage label {
    font-size: 10px;
  }
}
