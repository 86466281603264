.scroll-to-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease-in-out;
}

.scroll-to-top-button:hover {
  background-color: #555;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
}
