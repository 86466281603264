.new-catalog-books {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
}
.popular-topics {
  margin-top: 100px;
}
.catalog-topic {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}
.topics-img--wrap {
  position: relative;
}
.img__description {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #fff;
  visibility: hidden;
  opacity: 0;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  /* transition effect. not necessary */
  transition:
    opacity 0.2s,
    visibility 0.2s;
  width: 300px;
  height: 150px;
  background: rgba(0, 0, 0, 0.6);
  cursor: pointer;
}
.topics-img--wrap:hover .img__description {
  visibility: visible;
  opacity: 1;
}

.newCatalog__title {
  margin: 0 0 30px 0;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 39px;
}

@media (max-width: 599px) {
  .topics-img--wrap img {
    width: 100%;
    height: auto;
  }

  .img__description {
    width: 100%;
    height: 100%;
  }
}
