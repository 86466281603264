.container {
  width: 80%;
  margin: 0px auto;
}

.person-content {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 70%;
  margin: 20px auto;
  height: auto;
}

.image-div {
  width: 54%;
  height: 552px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.empty-div {
  background-color: #052928;
  height: 552px;
  width: 56%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.details {
  position: absolute;
  top: 11%;
  left: 40%;
  width: 50%;
  height: 77%;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 8px 8px -4px #32817f;
  padding: 20px;
}

.person-name {
  font-size: 30px;
  font-family: fangsong;
}

.person-job {
  font-size: 12px;
}

.person-description {
  margin-top: 20px;
  font-size: 12px;
  max-height: inherit;
  margin-bottom: 20px;
  text-align: justify;
}

.dialog-details {
  padding: 20px;
  text-align: justify;
}

.contact {
  margin-top: 20px;
  font-size: 20px;
  font-family: fangsong;
}

.social {
  font-size: 30px;
}

.person-link {
  margin: 0 7px 0 0;
  text-decoration: none;
  color: black;
}

@media only screen and (max-width: 1220px) {
  .person-content {
    width: 100%;
  }
}

@media only screen and (max-width: 860px) {
  .container {
    width: 100%;
  }
  .person-content {
    width: 100%;
  }
}

@media only screen and (max-width: 766px) {
  .details {
    width: 60%;
  }

  .person-name {
    font-size: 17px;
  }

  .person-job {
    font-size: 12px;
  }

  .person-description {
    height: 170px;
    overflow-y: scroll;
  }
}
