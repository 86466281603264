.filter-container {
  display: flex;
  align-items: center;
}

@media (max-width: 767px) {
  .filter-container {
    justify-content: flex-start;
  }
}
