.nav-main {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: '#ffffff';
}
.nav-main.shadow {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}
.nav-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  padding: 0px 9%;
  background-color: #ffffff;
  width: 100vw;
  height: 80px;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.15);
}

.nav-menu {
  display: flex;
  list-style: none;
  padding: 0;
}

.nav-menu a {
  padding: 1rem;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  width: max-content;
  letter-spacing: -0.015em;
  color: #a4a5ad;
  text-shadow: #a4a5ad;
  margin-left: 1.5rem;
}

.nav-menu a:hover {
  color: #3f54d1;
  text-decoration: underline !important;
  letter-spacing: -0.015em;
}

.nav-search {
  display: flex;
  align-items: center;
}

.search-input {
  width: 582px;
  height: 44px;
  left: 728px;
  top: 28px;
  background: #ffffff;
  box-shadow: 0px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  border: 1px solid whitesmoke;
  padding: 10px;
  padding-left: 35px;
}

.search-icn {
  padding: 5px 10px 5px 10px;
  position: absolute;
}

.search-i {
  width: 25px;
  display: flex;
  justify-content: end;
  color: #a4a5ad;
}

.search-input::placeholder {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #a4a5ad;
}

.btns {
  display: flex;
  float: right;
  gap: 20px;
  cursor: pointer;
  margin-right: 0;
  margin-left: auto;
}

.login-btn {
  text-decoration: none;
}

.login-btn:hover {
  text-decoration: none;
  border: none !important;
  background: none !important;
}

.login-btn button {
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #0965d1;
  border-radius: 14px;
  padding: 10px 25px;
  color: #0965d1;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
}

.login-btn:hover {
  box-sizing: border-box;
  background: #3f54d1;
  border: 2px solid #3f54d1;
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.signup-btn {
  box-sizing: border-box;
  background: #3f54d1;
  border: 2px solid #3f54d1;
  border-radius: 14px;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  font-style: normal;
  font-family: 'Poppins';
  padding: 5px 25px 5px 25px;
  cursor: pointer;
  text-align: center;
}

.signup-btn:hover {
  box-shadow: 0px 0px 1px 0px #3f54d1;
  cursor: pointer;
}

.nav-toggle {
  display: none;
}

.handburger__menu {
  position: fixed;
  background-color: #ffffff;
  display: none;
  flex-direction: column;
  text-align: center;
  gap: 10px;
  right: 0;
  top: 70px;
  width: 300px;
  padding: 50px;
  height: -webkit-fill-available;
}

.handburger__menu a {
  padding: 1rem;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.015em;
  color: #a4a5ad;
  text-shadow: #a4a5ad;
}

@media (max-width: 1578px) {
  .search-input {
    width: 448px;
  }
}

@media (max-width: 1450px) {
  .search-input {
    width: 277px;
  }
}

@media (max-width: 1206px) {
  .nav-container {
    padding: 0 40px;
  }
}

@media (max-width: 1025px) {
  .nav-container {
    gap: 0px;
    padding: 0 1%;
  }

  .search-input {
    width: 350px;
  }
}

@media (max-width: 770px) {
  .nav-container {
    padding: 0 3%;
  }
  .search-input {
    width: 300px;
  }
}

@media (max-width: 1005px) {
  .nav-menu {
    display: none;
  }

  .nav-toggle {
    display: flex;
    align-items: center;
    font-size: 30px;
    gap: 2rem;
    margin: 0 2rem 0 0;
  }

  .handburger__menu.open {
    display: flex;
  }

  .handburger__menu button {
    margin: 10px 0;
  }

  .btns {
    display: none;
  }

  .nav-search {
    display: none;
  }

  .nav-container {
    justify-content: space-between;
  }
}
