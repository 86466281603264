.search__filters {
  font-family: 'Montserrat';
  font-family: 500;
  margin-top: 100px !important;
  font-size: 14px;
}

.search__box {
  font-family: 'Montserrat';
  font-family: 500;
  margin-bottom: 30px;
}

@keyframes notificationDiv {
  0% {
    background-color: red;
    left: 0px;
    top: 0px;
  }
  25% {
    background-color: yellow;
    left: 200px;
    top: 0px;
  }
  50% {
    background-color: blue;
    left: 200px;
    top: 0px;
  }
  50% {
    background-color: hsl(127, 100%, 50%);
    left: 0px;
    top: 0px;
  }
}
.notificationDiv {
  font-size: large;
  background-color: #ffc107;
  position: relative;
  animation-name: notificationDiv;
  animation-duration: 10s;
  animation-delay: 2s;
  animation-iteration-count: 10;
}
