.Footer {
  margin-top: 20px;
  flex-direction: column;
  background: rgb(215, 233, 247);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
}

.Footer button {
  border: 1px solid grey;
  border-radius: 10px;
  background: transparent;
  cursor: pointer;
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  color: rgb(50, 50, 50);
  display: none;
}

@media (max-width: 1005px) {
  .Footer button {
    display: block;
  }
}
