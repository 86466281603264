.ResetPassword--div {
  background-image: url('../../asset/signup.png');
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ResetPassword,
.VerifyEmail {
  background: white;
  max-width: 600px;
  width: 600px;
  padding: 30px;
  height: max-content;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 45px;
}

.ResetPassword h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.ResetPassword p {
  margin-bottom: 10px;
}

.ResetPassword form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ResetPassword input[type='password'] {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.ResetPassword input[type='submit'] {
  width: 100%;
  height: 44px;
  background: #3f54d1;
  border-radius: 14px;
  padding: 15px 25px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 14px;
  color: white;
  border: none;
  transition: all 0.3s ease;
}

.ResetPassword input[type='submit']:hover {
  background-color: #344aaf;
}

.ResetPassword .error {
  color: #ff0000;
  margin-bottom: 15px;
}

@media (max-width: 1199px) {
  .ResetPassword {
    max-width: 500px;
    width: 90%;
    border-radius: 35px;
  }
}

@media (max-width: 991px) {
  .ResetPassword {
    width: 90%;
    border-radius: 25px;
    padding: 20px;
  }
}
