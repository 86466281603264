.Signup {
  background-image: url('../../asset/login.png');
  background-position: left;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  display: flex;
  justify-content: right;
  align-items: center;
  margin-top: 70px;
}

.MuiFormHelperText-root {
  font-size: 10px !important;
  padding: 0 !important;
  margin: 0 !important;
}

.Signup__content {
  height: max-content;
  margin-right: 123px;
}

.Signup__content h1 {
  color: white;
  font-family: 'Libre Caslon Text';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 40px;
  text-align: center;
}

.Signup__content-form {
  background-color: white;
  padding: 30px 50px;
  box-shadow: 0px 15px 30px 5px rgba(31, 31, 31, 0.15);
  border-radius: 45px;
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.Signup__content-form p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.02em;
}

.Signup__form-input {
  display: flex;
  gap: 21px;
}

.Signup__form-btn button {
  width: 107px;
  height: 44px;
  color: white;
  background: #3f54d1;
  border-radius: 14px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 14px;
  border: none;
  transition: all 0.3s ease;
}

.Signup__form-btn button:hover {
  background-color: #344aaf;
}

.Signup__form-btn button[disabled] {
  width: 107px;
  height: 44px;
  color: white;
  background: #808acb;
  border-radius: 14px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 14px;
  border: none;
  cursor: not-allowed;
}

@media (max-width: 786px) {
  .Signup {
    justify-content: center;
  }

  .Signup__content {
    margin-right: 0px;
  }
}

@media (max-width: 548px) {
  .Signup__content h1 {
    font-size: 25px;
    text-align: center;
  }

  .Signup__content-form {
    padding: 30px;
  }
}

@media (max-width: 460px) {
  .Signup__content-form {
    margin: 10px;
  }

  .Signup__form-input {
    flex-direction: column;
  }
}
