.tester-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.5rem;
}

.permit {
  margin-right: 10px;
  font-size: 1rem;
  color: inherit;
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  margin-bottom: 0.5rem;
}

.toggle-switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 24px;
}

.toggle-switch .slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

.toggle-switch.on .slider {
  background-color: #2196f3;
}

.toggle-switch.on .slider:before {
  transform: translateX(16px);
}

.toggle-switch.off .slider:before {
  transform: translateX(0);
}
