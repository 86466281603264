.SignupLoginDialogContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
  margin: 10px;
}

.SignupLoginDialogContainer button {
  border: 1px solid gray;
}
