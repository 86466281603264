.Login {
  background-image: url('../../asset/signup.png');
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Login__content {
  background: white;
  max-width: 600px;
  width: 600px;
  padding: 30px;
  height: max-content;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 45px;
}

.Login__content p {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.02em;
  color: #000000;
}

.Login__content-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.Login__content-input button {
  width: 91px;
  height: 44px;
  background: #3f54d1;
  border-radius: 14px;
  padding: 15px 25px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 14px;
  color: white;
  border: none;
  transition: all 0.3s ease;
}

.Login__content-input button:hover {
  background-color: #344aaf;
}

.Login__content-input button[disabled] {
  width: 91px;
  height: 44px;
  background: #808acb;
  border-radius: 14px;
  padding: 15px 25px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 14px;
  color: white;
  border: none;
  cursor: not-allowed;
}

.Login__content-forgotpassword {
  color: blue;
  cursor: pointer;
  margin-top: 10px;
}

@media (max-width: 630px) {
  .Login__content {
    margin: 10px;
  }
}
