.profile-container {
  height: 80vh;
}
.overlay-style-loader {
  z-index: 999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Faint backdrop */
  display: flex;
  justify-content: center;
  align-items: center;
}
.profileCard-container {
  max-width: 450px;
  max-height: 450px;
  border: 0.01px solid lightgrey;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  margin: 12rem auto;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}
.profile-header .avatar-pfp {
  margin: 2rem auto;
}
.profile-actions {
  display: flex;
  justify-content: space-between;
}
.avatar-initial {
  font-size: 2rem;
  font-weight: bold;
}

.profile-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.toggle--switches {
  margin-top: 15px;
  color: #525252;
}
