@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.about_container {
  background: linear-gradient(to left top, white, aliceblue);
  margin-top: 100px;
}
.top_container {
  text-align: center;
  padding: 50px;
}
.wrapper {
  background: linear-gradient(to left top, #c7d0ff, white);
  padding: 50px 50px 50px 50px;
  text-align: center;
}
.main_heading {
  text-align: center;
  text-decoration: underline;
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 30px;
}
.second_heading {
  color: darkblue;
  text-align: center;
  font-size: 60px;
  margin-bottom: 20px;
  font-family: 'tohoma';
}
.aboutUS_info {
  text-align: justify;
  padding: 0px 200px;
  color: #144272;
  font-family: 'tohoma';
  font-size: 18px;
}
.read_more {
  border-radius: 100rem;
  padding: 1rem;
  font-family: 'Avenir Next';
  font-size: 1rem;
  padding: 0.5rem 3rem;
  color: black;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 3px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(101deg, #78e4ff, #ff48fa);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  margin-top: 3rem;
  cursor: pointer;
}
.read_more:hover {
  box-shadow: none;
  color: white;
}
.products {
}
.product {
  display: grid;
  grid-template-columns: 1fr 2fr;
  background: #fff;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 0.5px solid lightblue;
  margin: 30px;
  padding: 30px;
}
.product:hover {
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
}
.description {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  font-family: 'tohoma';
  font-size: 16.5px;
  line-height: 1.6;
  margin-left: 0;
}
.profile_photo {
  margin: 20px;
  border: 1px solid lightblue;
}
.experience {
  padding: 0;
  margin-top: -50px;
}
.social_icons {
  display: inline;
  display: flex;
}
.icons {
  margin: 5px;
  width: 20px;
  height: 20px;
}
.icons:hover {
  transform: scale(1.5);
}
.about {
  font-family: 'Karla';
  line-height: 1.5;
  font-size: 18.5;
}
.thumb {
  width: 100%;
  height: 100%;
  background: #fff;
}
a {
  text-decoration: none !important;
}
.expand_btn {
  border-radius: 10px;
  font-family: 'Avenir Next';
  font-size: 1rem;
  padding: 0.3rem 3rem;
  color: black;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 3px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(101deg, #78e4ff, #ff48fa);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  margin-top: 1rem;
  cursor: pointer;
}
.expand_btn:hover {
  box-shadow: none;
  color: white;
}

.home-btn {
  position: absolute;
  top: 40px;
  left: 30px;
}

/* .product:nth-of-type(odd) {
    flex-direction: row-reverse;
  } */
@media only screen and (max-width: 600px) {
  .about_container {
    background-color: aliceblue;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
  }

  .about-content {
    padding: 20px;
  }

  .wrapper {
    padding: 0;
    margin: 0;
  }
  .aboutUS_info {
    text-align: justify;
    padding: 0;
    margin: 0;
  }
  .products {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-top: 50px;
  }
  .product {
    display: grid;
    grid-template-columns: 1fr;
    margin: 4px;
    background: #fff;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .thumb {
    background: #144272;
    /* display: flex;
    justify-content: center; */
    padding: 20px;
    border: 1px solid black;
    width: 50px;
    height: 50px;
  }
  .person_name {
    text-align: center;
  }
  .social_icons {
    display: flex;
    justify-content: center;
  }
  .job {
    text-align: center;
  }
  .experience {
    text-align: center;
  }
  .read_more {
    border-radius: 100rem;
    padding: 1rem;
    font-family: 'Avenir Next';
    font-size: 1rem;
    padding: 0.5rem 3rem;
    color: black;
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    border: solid 3px transparent;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(101deg, #78e4ff, #ff48fa);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset;
  }
  .read_more:hover {
    box-shadow: none;
    color: white;
  }
  .home-btn {
    position: absolute;
    top: 25px;
    left: 25px;
  }
}
